import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import liff from '@line/liff';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import OpenWindowParams from '@line/liff';

@Injectable({
    providedIn: 'root'
})
export class LineLiffService {

    constructor(private http: HttpClient, private router: Router) { }

    /**
     * @param liffId
     * @returns line profile
     * @description for registration module
     */
    async lineProfile(liffId: string): Promise<any> {
        console.log("lineProfile")
        await liff.init({ liffId: liffId });

        if (liff.isInClient()) {
            return liff.getProfile();
        } else {
            if (liff.isLoggedIn()) {
                return liff.getProfile();
            } else {
                return liff.login();
            }
        }
    }

    async openWindow(params: any): Promise<any>{
        await liff.openWindow(params);
    }

    /**
    * @param liffId
    * @returns line profile
    * @description for other module need verify profile.userId
    */
    async init(liffId: string): Promise<any> {
        console.log("init")

        await liff.init({ liffId: liffId });

        if (liff.isInClient()) {
            return this.verifyProfile();
        } else {
            if (liff.isLoggedIn()) {
                return this.verifyProfile();
            } else {
                return liff.login();
            }
        }
    }

    async initOnProfile(liffId: string): Promise<any> {
        console.log("initOnProfile")

        await liff.init({ liffId: liffId });

        if (liff.isInClient()) {
            return await liff.getProfile();
        } else {
            if (liff.isLoggedIn()) {
                return await liff.getProfile();
            } else {
                return liff.login();
            }
        }
    }

    /*
    async verifyLeave() {

        const profile = await liff.getProfile();
        try {
            await lastValueFrom(this.http.post(`${environment.apiHost}/api/verified`, {
                user_id: profile.userId
            }));
            return profile;
        } catch (error: any) {
            if (error.status === 403) {
                return this.router.navigateByUrl('/profile');
            }
            else {
                return this.router.navigateByUrl('/error');
            }
        }
    }
    */

    async verifyProfile() {

        const profile = await liff.getProfile();
        try {
            await lastValueFrom(this.http.post(`${environment.apiHost}/api/verify`, {
                user_id: profile.userId
            }));
            return profile;
        } catch (error: any) {
            if (error.status === 403) {
                return this.router.navigateByUrl('/profile');
            }
            else {
                return this.router.navigateByUrl('/error');
            }
        }
    }

    async sendMessages(liffId: string, message: string): Promise<any> {
        console.log("sendMessages")

        await liff.init({ liffId: liffId });

        if (liff.isInClient()) {
            return liff.sendMessages([
                {
                    type: 'text',
                    text: message,
                },
            ]);
        }
    }

    windowClose() {
        console.log("windowClose")

        liff.closeWindow();
    }
}
